<template>
    <table-container>
        <data-iterator
            ref="iterator"
            :api="api"
            :filters.sync="filters"
            :fixed-filters="fixedFilters"
            @update:filters="updateFilters"
        >
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    @input="updateFilters"
                ></table-filters>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-action
                >
                    <template v-slot:[`item.name`]="{ item }">
                        <v-tooltip
                            top
                            :disabled="!nameTooltipIds.includes(item.id)"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-truncate-tooltip="{
                                        id: item.id,
                                        type: 'nameTooltipIds',
                                    }"
                                    v-bind="attrs"
                                    class="text-truncate"
                                    style="max-width: 30vw"
                                    v-on="on"
                                >
                                    {{ item.name }}
                                </div>
                            </template>
                            <span>{{ item.name }}</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.description`]="{ item }">
                        <v-tooltip
                            top
                            :disabled="!descriptionTooltipIds.includes(item.id)"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-truncate-tooltip="{
                                        id: item.id,
                                        type: 'descriptionTooltipIds',
                                    }"
                                    v-bind="attrs"
                                    class="text-truncate"
                                    style="max-width: 30vw"
                                    v-on="on"
                                >
                                    {{ item.description }}
                                </div>
                            </template>
                            <span>
                                <pre>{{ item.description }}</pre>
                            </span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.customExportParameters`]="{ item }">
                        <div class="text-truncate">
                            {{ getParamNames(item) }}
                        </div>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <v-btn
                            text
                            icon
                            @click="
                                selectedItem = item
                                showExecuteModal = true
                            "
                        >
                            <v-icon>play_arrow</v-icon>
                        </v-btn>
                    </template>
                </d-table>
            </template>
        </data-iterator>

        <execute-modal
            v-model="showExecuteModal"
            :item="selectedItem"
        ></execute-modal>
    </table-container>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from '@/pages/customExport/mixins/ShowHeaders'
import Config from '@/config.loader'
import SelectApiClientType from '@/api/SelectApiClientType'
import ExecuteModal from '@/components/pages/customExport/ExecuteModal.vue'

export default {
    components: {
        ExecuteModal,
    },
    directives: {
        truncateTooltip: {
            componentUpdated(el, binding, vnode) {
                if (
                    vnode.context[binding.value.type].includes(binding.value.id)
                ) {
                    return
                }

                if (el.scrollWidth > el.offsetWidth) {
                    vnode.context[binding.value.type].push(binding.value.id)
                }
            },
        },
    },
    mixins: [DataIteratorMixin, DataTableMixin, Headers],
    props: {
        isUpdate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            api: RestApiType.CUSTOM_EXPORT,
            baseURL: Config.getConfigValue('VUE_APP_ADMIN_API_BASE'),
            nameTooltipIds: [],
            descriptionTooltipIds: [],
            categoryTooltipIds: [],
            fixedFilters: {
                order: {
                    position: 'asc',
                },
                isUpdate: this.isUpdate ? 1 : 0,
            },
            listFilters: [
                {
                    key: 'category',
                    label: 'labels.category',
                    inputType: 'select',
                    entity: SelectApiClientType.CUSTOM_EXPORT_CATEGORY,
                    quick: true,
                    queryParams: { isUpdate: this.isUpdate ? 1 : 0 },
                },
            ],
            showExecuteModal: false,
            selectedItem: null,
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.custom_export')
        },
    },
    methods: {
        getParamNames(item) {
            return item.customExportParameters
                .map((param) => param.name)
                .join(', ')
        },
    },
}
</script>
