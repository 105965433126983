<template>
    <modal-dialog
        v-model="show"
        :max-width="maxWidth"
        :cancel-button="true"
        :ok-text="
            preview
                ? $t('actions.preview')
                : item && item.isUpdate
                ? $t('actions.execute_query')
                : $t('actions.export')
        "
        :ok-loading="loading"
        :ok-disabled="!isValid"
        :scrollable="true"
        @ok="validate"
    >
        <div>
            <div v-if="error">
                <v-alert type="error">
                    {{ error }}
                </v-alert>
            </div>

            <div v-if="updateSuccess">
                <v-alert type="success">
                    {{ $t('notifications.success') }}
                </v-alert>
            </div>

            <div v-if="item && !showTable">
                <v-row>
                    <v-col>
                        <h3>{{ item.name }}</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <pre>{{ item.description }}</pre>
                    </v-col>
                </v-row>

                <template
                    v-if="
                        item.customExportParameters &&
                        item.customExportParameters.length
                    "
                >
                    <v-form v-model="isValid" autocomplete="off">
                        <template
                            v-for="(paramDef,
                            index) in item.customExportParameters"
                        >
                            <form-one-col-row
                                v-if="paramDef.type === 'number'"
                                :key="index"
                            >
                                <template>
                                    <text-input
                                        v-model="params[paramDef.name]"
                                        v-validate="'required'"
                                        :name="paramDef.name"
                                        :error-messages="
                                            errors.collect(paramDef.name)
                                        "
                                        :data-vv-as="paramDef.name"
                                        :label="
                                            paramDef.name +
                                            ' (' +
                                            paramDef.type +
                                            ')'
                                        "
                                        autocomplete="off"
                                        type="number"
                                    ></text-input>
                                </template>
                            </form-one-col-row>

                            <form-one-col-row
                                v-if="paramDef.type === 'string'"
                                :key="index"
                            >
                                <template>
                                    <text-input
                                        v-model="params[paramDef.name]"
                                        v-validate="'required'"
                                        :name="paramDef.name"
                                        :error-messages="
                                            errors.collect(paramDef.name)
                                        "
                                        :data-vv-as="paramDef.name"
                                        :label="
                                            paramDef.name +
                                            ' (' +
                                            paramDef.type +
                                            ')'
                                        "
                                        autocomplete="off"
                                    ></text-input>
                                </template>
                            </form-one-col-row>

                            <form-one-col-row
                                v-if="paramDef.type === 'number_array'"
                                :key="index"
                            >
                                <template>
                                    <tag-input
                                        v-model="params[paramDef.name]"
                                        v-validate="'required|numeric'"
                                        :name="paramDef.name"
                                        :data-vv-as="paramDef.name"
                                        :label="
                                            paramDef.name +
                                            ' (' +
                                            paramDef.type +
                                            ')'
                                        "
                                        :error-messages="
                                            errors.collect(paramDef.name)
                                        "
                                    ></tag-input>
                                </template>
                            </form-one-col-row>

                            <form-one-col-row
                                v-if="paramDef.type === 'string_array'"
                                :key="index"
                            >
                                <template>
                                    <tag-input
                                        v-model="params[paramDef.name]"
                                        v-validate="'required'"
                                        :name="paramDef.name"
                                        :data-vv-as="paramDef.name"
                                        :label="
                                            paramDef.name +
                                            ' (' +
                                            paramDef.type +
                                            ')'
                                        "
                                        :error-messages="
                                            errors.collect(paramDef.name)
                                        "
                                    ></tag-input>
                                </template>
                            </form-one-col-row>

                            <form-one-col-row
                                v-if="selectList.includes(paramDef.type)"
                                :key="index"
                            >
                                <template>
                                    <entity-select-input
                                        v-model="params[paramDef.name]"
                                        v-validate="'required'"
                                        :label="
                                            paramDef.name +
                                            ' (' +
                                            paramDef.type +
                                            ')'
                                        "
                                        :name="paramDef.name"
                                        :entity="selectMap[paramDef.type]"
                                        :data-vv-as="paramDef.name"
                                        :error-messages="
                                            errors.collect(paramDef.name)
                                        "
                                        :use-id-field="true"
                                        autocomplete
                                        :multi-select="
                                            multiSelectList.includes(
                                                paramDef.type
                                            )
                                        "
                                    ></entity-select-input>
                                </template>
                            </form-one-col-row>
                        </template>
                    </v-form>
                </template>

                <form-one-col-row v-if="!item.isUpdate">
                    <template>
                        <checkbox-input
                            v-model="preview"
                            label="labels.show_preview"
                            name="isUpdate"
                        ></checkbox-input>
                    </template>
                </form-one-col-row>
            </div>

            <div v-if="showTable">
                <v-row>
                    <v-col>
                        <h3>{{ item.name }}</h3>
                        <br />
                        <p>
                            {{ $t('labels.showing_results') }}:
                            {{ tableCount }}/{{ tableMax }}
                        </p>
                    </v-col>
                </v-row>
                <v-simple-table dense fixed-header height="50vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    v-for="(header, idx) in getHeaders()"
                                    :key="`h-${idx}`"
                                    class="text-left"
                                >
                                    {{ header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(tableRow, index) in tableData"
                                :key="index"
                            >
                                <td
                                    v-for="(header, idx) in getHeaders()"
                                    :key="`r-${index}-${idx}`"
                                >
                                    {{ tableRow[header] }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </div>

        <template v-slot:footer>
            <template v-if="showTable">
                <v-btn @click="back()">{{ $t('actions.back') }}</v-btn>
            </template>
        </template>
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/dialog/ModalDialog'
import FormOneColRow from '@/components/form/FormOneColRow.vue'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput.vue'
import CustomExportApiClient from '@/api/RestApi/CustomExportApiClient'
import TextInput from '@/components/form/Inputs/TextInput.vue'
import TagInput from '@/components/form/Inputs/TagInput.vue'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput.vue'
import SelectApiClientType from '@/api/SelectApiClientType'

export default {
    components: {
        EntitySelectInput,
        TextInput,
        CheckboxInput,
        FormOneColRow,
        ModalDialog,
        TagInput,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            show: false,
            preview: false,
            showTable: false,
            tableData: [],
            error: null,
            loading: false,
            updateSuccess: false,
            params: {},
            maxWidth: '40%',
            isValid: true,
            multiSelectList: [
                'order_array',
                'restaurant_array',
                'company_array',
                'user_array',
            ],
            selectList: [
                'order',
                'restaurant',
                'company',
                'user',
                'order_array',
                'restaurant_array',
                'company_array',
                'user_array',
            ],
            selectMap: {
                order: SelectApiClientType.ORDER,
                restaurant: SelectApiClientType.RESTAURANT,
                company: SelectApiClientType.COMPANY,
                user: SelectApiClientType.USER,
                order_array: SelectApiClientType.ORDER,
                restaurant_array: SelectApiClientType.RESTAURANT,
                company_array: SelectApiClientType.COMPANY,
                user_array: SelectApiClientType.USER,
            },
            tableCount: 0,
            tableMax: 0,
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.show = val
                this.reset()
            },
        },
        show(val) {
            this.$emit('input', val)
        },
    },
    methods: {
        reset() {
            this.showTable = false
            this.preview = false
            this.tableData = []
            this.error = null
            this.loading = false
            this.updateSuccess = false
            this.params = {}
            this.maxWidth = '40%'
            this.isValid = true
            this.tableCount = 0
            this.tableMax = 0
        },
        back() {
            this.showTable = false
            this.tableData = []
            this.error = null
            this.loading = false
            this.updateSuccess = false
            this.maxWidth = '40%'
        },
        validate() {
            this.loading = true
            setTimeout(() => {
                this.$validator.validate().then((isValid) => {
                    if (!isValid) {
                        this.loading = false
                        return
                    }
                    this.execute()
                })
            }, 1000)
        },
        execute() {
            if (this.preview) {
                this.showTable = true
                this.maxWidth = '70%'
                CustomExportApiClient.executeJson(this.item.token, this.params)
                    .then((response) => {
                        this.tableData = response.data.data
                        this.tableCount = response.data.count
                        this.tableMax = response.data.total
                        this.loading = false
                        this.preview = false
                    })
                    .catch((error) => {
                        this.error =
                            error.response?.data?.errors?.error ??
                            error.response?.errors?.error ??
                            'Unknown error'
                        this.loading = false
                    })
            } else {
                if (!this.item.isUpdate) {
                    CustomExportApiClient.download(this.item.token, this.params)
                        .then((response) => {
                            const blob = new Blob([response], {
                                type: 'text/csv',
                            })
                            const blobUrl = URL.createObjectURL(blob)
                            const link = document.createElement('a')
                            link.href = blobUrl
                            link.download = this.orderIdentifier
                            link.click()
                            URL.revokeObjectURL(link.href)
                            this.loading = false
                        })
                        .catch((error) => {
                            this.error =
                                error.response?.data?.errors?.error ??
                                error.response?.errors?.error ??
                                'Unknown error'
                            this.loading = false
                        })
                } else {
                    CustomExportApiClient.execute(this.item.token, this.params)
                        .then(() => {
                            this.updateSuccess = true
                            this.loading = false
                        })
                        .catch((error) => {
                            this.error =
                                error.response?.data?.errors?.error ??
                                error.response?.errors?.error ??
                                'Unknown error'
                            this.loading = false
                        })
                }
            }
        },
        getHeaders() {
            if (!(this.tableData instanceof Array) || !this.tableData.length) {
                return []
            }

            return Object.keys(this.tableData[0])
        },
    },
}
</script>
