<template>
    <div>
        <page-title>{{ $t('menu.custom_export') }}</page-title>
        <show-table></show-table>
    </div>
</template>

<script>
import ShowTable from '@/components/pages/customExport/ShowTable.vue'
import PageTitle from '@/components/layout/components/pageTitle.vue'

export default {
    components: { PageTitle, ShowTable },
}
</script>
