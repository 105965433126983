var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-dialog',{attrs:{"max-width":_vm.maxWidth,"cancel-button":true,"ok-text":_vm.preview
            ? _vm.$t('actions.preview')
            : _vm.item && _vm.item.isUpdate
            ? _vm.$t('actions.execute_query')
            : _vm.$t('actions.export'),"ok-loading":_vm.loading,"ok-disabled":!_vm.isValid,"scrollable":true},on:{"ok":_vm.validate},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.showTable)?[_c('v-btn',{on:{"click":function($event){return _vm.back()}}},[_vm._v(_vm._s(_vm.$t('actions.back')))])]:_vm._e()]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',[(_vm.error)?_c('div',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1):_vm._e(),(_vm.updateSuccess)?_c('div',[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t('notifications.success'))+" ")])],1):_vm._e(),(_vm.item && !_vm.showTable)?_c('div',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.item.name))])])],1),_c('v-row',[_c('v-col',[_c('pre',[_vm._v(_vm._s(_vm.item.description))])])],1),(
                    _vm.item.customExportParameters &&
                    _vm.item.customExportParameters.length
                )?[_c('v-form',{attrs:{"autocomplete":"off"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_vm._l((_vm.item.customExportParameters),function(paramDef,index){return [(paramDef.type === 'number')?_c('form-one-col-row',{key:index},[[_c('text-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":paramDef.name,"error-messages":_vm.errors.collect(paramDef.name),"data-vv-as":paramDef.name,"label":paramDef.name +
                                        ' (' +
                                        paramDef.type +
                                        ')',"autocomplete":"off","type":"number"},model:{value:(_vm.params[paramDef.name]),callback:function ($$v) {_vm.$set(_vm.params, paramDef.name, $$v)},expression:"params[paramDef.name]"}})]],2):_vm._e(),(paramDef.type === 'string')?_c('form-one-col-row',{key:index},[[_c('text-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":paramDef.name,"error-messages":_vm.errors.collect(paramDef.name),"data-vv-as":paramDef.name,"label":paramDef.name +
                                        ' (' +
                                        paramDef.type +
                                        ')',"autocomplete":"off"},model:{value:(_vm.params[paramDef.name]),callback:function ($$v) {_vm.$set(_vm.params, paramDef.name, $$v)},expression:"params[paramDef.name]"}})]],2):_vm._e(),(paramDef.type === 'number_array')?_c('form-one-col-row',{key:index},[[_c('tag-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|numeric'),expression:"'required|numeric'"}],attrs:{"name":paramDef.name,"data-vv-as":paramDef.name,"label":paramDef.name +
                                        ' (' +
                                        paramDef.type +
                                        ')',"error-messages":_vm.errors.collect(paramDef.name)},model:{value:(_vm.params[paramDef.name]),callback:function ($$v) {_vm.$set(_vm.params, paramDef.name, $$v)},expression:"params[paramDef.name]"}})]],2):_vm._e(),(paramDef.type === 'string_array')?_c('form-one-col-row',{key:index},[[_c('tag-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":paramDef.name,"data-vv-as":paramDef.name,"label":paramDef.name +
                                        ' (' +
                                        paramDef.type +
                                        ')',"error-messages":_vm.errors.collect(paramDef.name)},model:{value:(_vm.params[paramDef.name]),callback:function ($$v) {_vm.$set(_vm.params, paramDef.name, $$v)},expression:"params[paramDef.name]"}})]],2):_vm._e(),(_vm.selectList.includes(paramDef.type))?_c('form-one-col-row',{key:index},[[_c('entity-select-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":paramDef.name +
                                        ' (' +
                                        paramDef.type +
                                        ')',"name":paramDef.name,"entity":_vm.selectMap[paramDef.type],"data-vv-as":paramDef.name,"error-messages":_vm.errors.collect(paramDef.name),"use-id-field":true,"autocomplete":"","multi-select":_vm.multiSelectList.includes(
                                            paramDef.type
                                        )},model:{value:(_vm.params[paramDef.name]),callback:function ($$v) {_vm.$set(_vm.params, paramDef.name, $$v)},expression:"params[paramDef.name]"}})]],2):_vm._e()]})],2)]:_vm._e(),(!_vm.item.isUpdate)?_c('form-one-col-row',[[_c('checkbox-input',{attrs:{"label":"labels.show_preview","name":"isUpdate"},model:{value:(_vm.preview),callback:function ($$v) {_vm.preview=$$v},expression:"preview"}})]],2):_vm._e()],2):_vm._e(),(_vm.showTable)?_c('div',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.item.name))]),_c('br'),_c('p',[_vm._v(" "+_vm._s(_vm.$t('labels.showing_results'))+": "+_vm._s(_vm.tableCount)+"/"+_vm._s(_vm.tableMax)+" ")])])],1),_c('v-simple-table',{attrs:{"dense":"","fixed-header":"","height":"50vh"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.getHeaders()),function(header,idx){return _c('th',{key:("h-" + idx),staticClass:"text-left"},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.tableData),function(tableRow,index){return _c('tr',{key:index},_vm._l((_vm.getHeaders()),function(header,idx){return _c('td',{key:("r-" + index + "-" + idx)},[_vm._v(" "+_vm._s(tableRow[header])+" ")])}),0)}),0)]},proxy:true}],null,false,579664847)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }